import React, {
  createContext, useState, useCallback, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';

export const defaultValues = {
  // Menu
  isMenuOpen: false,
  // Subscription form things
  isRegistrationFormOpen: false,

  // Propspect registration values
  // Use these if the email input is on the Hero section
  authorizationRequest: {},
  email: '',
  phone: '',
  // allows user to close the registration form with only the X button
  shouldCloseFormWithKeys: false,
};

const SiteContext = createContext();

const SiteContextProvider = ({
  children,
  initialValues: {
    email: initialEmail = defaultValues.email,
    phone: initialPhone = defaultValues.phone,
    authorizationRequest: initialAuthorizationRequest = defaultValues.authorizationRequest,
  },
}) => {
  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(
    defaultValues.isRegistrationFormOpen,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(defaultValues.isMenuOpen);
  const [
    shouldCloseFormWithKeys,
    setShouldCloseFormWithKeys,
  ] = useState(defaultValues.shouldCloseFormWithKeys);

  const [email, setEmail] = useState(initialEmail);
  const [phone, setPhone] = useState(initialPhone);

  const [authorizationRequest, setAuthorizationRequest] = useState(
    initialAuthorizationRequest,
  );

  const restartFlowEventsValues = {
    phone: false,
    mailConfirmation: false,
    personalInformation: false,
    address: false,
    unknownZipCodeBtn: false,
    contactData: false,
    personalInfoDisplayed: false,
    personalInfoLastName: false,
    personalInfoCurp: false,
    contactDataDisplayed: false,
    addressDisplayed: false,
    nipDisplayed: false,
    otpSent: false,
    financialGoals: false,
    redirectionHappyPath: true,
  };

  const [registrationFlowEvents, setRegistrationFlowEvents] = useState(restartFlowEventsValues);

  // Updates registration flow events for ETL
  const updateRegistrationFlowEvents = useCallback(values => {
    setRegistrationFlowEvents(values);
  }, []);

  const openRegistrationForm = useCallback(() => {
    setIsRegistrationFormOpen(true);
    setIsMenuOpen(false);
    updateRegistrationFlowEvents(restartFlowEventsValues);
  }, []);

  const closeRegistrationForm = useCallback(force => {
    if (force || shouldCloseFormWithKeys) {
      setShouldCloseFormWithKeys(true);
      setIsRegistrationFormOpen(false);
    }
  }, [shouldCloseFormWithKeys]);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prevValue => !prevValue);
    setIsRegistrationFormOpen(false);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setIsRegistrationFormOpen(false);
  }, []);

  const [formPosition, setFormPosition] = useState(0);

  const updateFormPosition = useCallback(value => {
    setFormPosition(value);
  }, []);

  const emailInputFormBox = useRef(null);

  const focusEmailInputFormBox = useCallback(() => {
    if (emailInputFormBox.current) {
      emailInputFormBox.current.focus({ preventScroll: true, focusVisible: true });
    }
  }, []);

  const [paginationValues, setPaginationValues] = useState({});
  const updatePaginationValues = useCallback(values => {
    setPaginationValues(values);
  }, []);

  const [isFormBoxAvailable, setIsFormBoxAvailable] = useState(false);
  const updateIsFormBoxAvailable = useCallback(values => {
    setIsFormBoxAvailable(values);
  }, []);

  const [calculatorViewed, setCalculatorViewed] = useState(false);
  const updateIsCalculatorViewed = useCallback(values => {
    setCalculatorViewed(values);
  }, []);

  const [calculatorUsed, setCalculatorUsed] = useState(false);
  const updateIsCalculatorUsed = useCallback(values => {
    setCalculatorUsed(values);
  }, []);

  const [discoveryUrlsList, setDiscoveryUrlsList] = useState(false);
  const updateDiscoveryUrlsList = useCallback(values => {
    setDiscoveryUrlsList(values);
  }, []);

  // Pop Up experiment
  const [displayPopUpContext, setDisplayPopUpContext] = useState(true);
  const updateDisplayPopUpContext = useCallback(value => {
    setDisplayPopUpContext(value);
  }, []);

  // Product Choice Selection Experiment
  const [productChoiceSelection, setProductChoiceSelection] = useState([]);

  return (
    <SiteContext.Provider value={{
      isRegistrationFormOpen,
      isMenuOpen,
      openRegistrationForm,
      closeRegistrationForm,
      toggleMenu,
      closeMenu,
      email,
      phone,
      setPhone,
      setEmail,
      setShouldCloseFormWithKeys,
      authorizationRequest,
      setAuthorizationRequest,
      formPosition,
      updateFormPosition,
      emailInputFormBox,
      focusEmailInputFormBox,
      paginationValues,
      updatePaginationValues,
      registrationFlowEvents,
      updateRegistrationFlowEvents,
      isFormBoxAvailable,
      updateIsFormBoxAvailable,
      calculatorViewed,
      updateIsCalculatorViewed,
      calculatorUsed,
      updateIsCalculatorUsed,
      discoveryUrlsList,
      updateDiscoveryUrlsList,
      displayPopUpContext,
      updateDisplayPopUpContext,
      productChoiceSelection,
      setProductChoiceSelection,
    }}
    >
      {children}
    </SiteContext.Provider>
  );
};

SiteContextProvider.defaultProps = {
  initialValues: {},
};

SiteContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.shape({
    authorizationRequest: PropTypes.shape({
      flowId: PropTypes.string,
      id: PropTypes.string,
    }),
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};

function useSiteContext() {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error('useSiteContext must be used within a SiteContextProvider');
  }

  return context;
}

export {
  SiteContextProvider,
  useSiteContext,
};
